import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import Checkpoint from './Checkpoint';
import template from './template';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`query {
      image: imageSharp (fluid: {
        originalName: { eq: "Driver-Advantages.jpg" }
      }) {
        fluid(maxWidth: 1624, maxHeight: 942, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      price: imageSharp (fluid: {
        originalName: { eq: "Driver-Advantages-Price.jpg" }
      }) {
        fluid(maxWidth: 1400, maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      checkbox: imageSharp (fluid: { originalName: { eq: "Checkmark.png" }}) {
        fluid(maxWidth: 108, maxHeight: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }`}
    render={({ checkbox, image, price }) => (
      <Grid container justify='center' alignContent='center' alignItems='flex-end'
        className={styles.container}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={styles.checkpoints}>
          <Typography color='dark' variant='h3' align='left' weight='bold'
            className={styles.titleContainer}>
            {i18n('How it helps you')}
          </Typography>
          {template.map((t, i) => (
            <Checkpoint key={i}
              checkbox={checkbox}
              text={t}/>
          ))}
        </Grid>
        <Grid item container xs={12} sm={12} md={6} lg={6} className={styles.picture}
          alignContent='center'
          alignItems='flex-end'>
          <Grid item xs={12} xl={12}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={image.fluid}/>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12} className={styles.picturePrice}
          alignContent='center'
          alignItems='flex-end'>
          <Grid item xs={12} xl={12}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              fluid={price.fluid}
              alt={i18n('Mobile app for every car driver')}/>
          </Grid>
        </Grid>
      </Grid>
    )
  }/>
);